// let Dexie = require('dexie').default;
// let _ = require('lodash');

import axios from 'axios';
import * as dayjs from 'dayjs';
import PullToRefresh from 'pulltorefreshjs';
import Vue from 'vue';

(async function() {

    if (!('serviceWorker' in navigator)) {
        console.error('serviceWorker not available');
        return;
    }

    navigator.serviceWorker.register('sw.js').then((reg) => {
        // registration worked
        console.log('Registration succeeded. Scope is ' + reg.scope);
    }).catch((error) => {
        // registration failed
        console.error('Registration failed with ' + error);
    });

    if (!('indexedDB' in window)) {
        console.error('IndexedDB not available');
        return;
    }

    // let db = new Dexie('weather');
    //
    // await db.version(1).stores({
    //     activities: '&slug',
    //     codes: '&slug',
    //     species: '&slug',
    //     limits: '++,[species+water_type+zone]',
    //     seasons: '++',
    //     counties: '&slug',
    //     waters: '&slug,county',
    //     depths: '&slug',
    //     settings: '&slug'
    // });

    new Vue({
        el: '#appMain',
        data: {
            weather: null,
            lastQuery: 0,
            rateLimited: false,
        },
/*
        methods: {
            updateUser: function(user) {
                this.user.displayName = user.displayName;
                this.user.loggedIn = true;

                this.db.settings.put({
                    slug: 'user',
                    displayName: user.displayName,
                    apiToken: user.apiToken
                });

                this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.apiToken;

                this.showLogin = false;
            },
            doLogout: function() {
                this.user.displayName = '';
                this.user.loggedIn = false;

                delete this.axios.defaults.headers.common['Authorization'];
                // zzz how to handle premium items like depth maps?

                this.db.settings.delete('user');
            },
            updateCountiesFromDB: function() {
                console.debug('%c enter updateCountiesFromDB', 'color: yellow');
                let vue = this;
                return vue.db.transaction('r', ['counties', 'waters'], async tx => {
                    await vue.db.counties.each(dbCounty => {
                        let vueCounty_index = vue.counties.findIndex(vueCounty => vueCounty.slug == dbCounty.slug);
                        if (vueCounty_index >= 0) {
                            Object.keys(dbCounty).forEach(function(prop) {
                                if (vue.counties[vueCounty_index].hasOwnProperty(prop)) {
                                    vue.counties[vueCounty_index][prop] = dbCounty[prop];
                                }
                            });
                        } else {
                            dbCounty.waters = [];
                            dbCounty.watersLoaded = false;
                            dbCounty.showWaters = false;
                            let idx = _.sortedIndexBy(vue.counties, dbCounty, county => county.name.toLowerCase());
                            vue.counties.splice(idx, 0, dbCounty);
                        }
                    });
                    for (let i = vue.counties.length - 1; i >= 0; i--) {
                        vue.db.counties.get(vue.counties[i].slug)
                            .then(function(ret) {
                                if (ret === undefined) {
                                    vue.counties.splice(i, 1);
                                }
                            })
                        ;
                    }
                });
            },
            updateWatersFromDB: function() {
                console.debug('enter updateWatersFromDB');
                let vue = this;
                let promises = [];
                let nDepthsLoaded = 0;
                vue.counties.forEach((vueCounty, vueCounty_index) => {
                    promises.push(vue.db.waters
                        .where({county: vueCounty.slug})
                        .sortBy('name')
                        .then(dbWaters => {
                            dbWaters.forEach(async dbWater => {
                                dbWater.depthLoaded = false;
                                await vue.db.depths.get(dbWater.slug)
                                    .then(dbDepth => {
                                        if (dbDepth !== undefined) {
                                            dbWater.depthLoaded = true;
                                            nDepthsLoaded++;
                                        }
                                    })
                                ;
                                let vueWater_index = vue.counties[vueCounty_index].waters.findIndex(vueWater => vueWater.slug == dbWater.slug);
                                if (vueWater_index >= 0) {
                                    Object.keys(dbWater).forEach(function(prop) {
                                        if (vue.counties[vueCounty_index].hasOwnProperty(prop)) {
                                            vue.counties[vueCounty_index][prop] = vueCounty[prop];
                                        }
                                    });
                                } else {
                                    // vue.counties[vueCounty_index].waters.push(dbWater);
                                    let idx = _.sortedIndexBy(vue.counties[vueCounty_index].waters, dbWater, water => water.name.toLowerCase());
                                    vue.counties[vueCounty_index].waters.splice(idx, 0, dbWater);
                                }
                            });
                            return vueCounty_index;
                        })
                        .then(vueCounty_index => { vue.counties[vueCounty_index].watersLoaded = true; })
                    );
                });
                return Promise.all(promises); // zzz remove deleted waters from vue
            },
            updateActivitiesFromDB: async function() {
                console.debug('enter updateActivitiesFromDB');
                let vue = this;
                return vue.db.transaction('r', ['activities'], async tx => {
                    await vue.db.activities.each(dbActivity => {
                        let vueActivity_index = vue.activities.findIndex(vueActivity => vueActivity.slug == dbActivity.slug);
                        if (vueActivity_index >= 0) {
                            Object.keys(dbActivity).forEach(function(prop) {
                                if (vue.activities[vueActivity_index].hasOwnProperty(prop)) {
                                    vue.activities[vueActivity_index][prop] = dbActivity[prop];
                                }
                            });
                        } else {
                            dbActivity.codes = [];
                            dbActivity.allowed = true;
                            vue.activities.push(dbActivity);
                        }
                    });
                    for (let i = vue.activities.length - 1; i >= 0; i--) {
                        vue.db.activities.get(vue.activities[i].slug)
                            .then(function(ret) {
                                if (ret === undefined) {
                                    vue.activities.splice(i, 1);
                                }
                            })
                        ;
                    }
                });
            },
            updateSpeciesFromDB: async function() {
                console.debug('enter updateSpeciesFromDB');
                let vue = this;
                return vue.db.transaction('r', ['species'], async tx => {
                    await vue.db.species.each(dbSp => {
                        let vueSp_index = vue.species.findIndex(vueSp => vueSp.slug == dbSp.slug);
                        if (vueSp_index >= 0) {
                            Object.keys(dbSp).forEach(function(prop) {
                                if (vue.species[vueSp_index].hasOwnProperty(prop)) {
                                    vue.species[vueSp_index][prop] = dbSp[prop];
                                }
                            });
                        } else {
                            // vue.species.push(dbSp);
                            let idx = _.sortedIndexBy(vue.species, dbSp, sp => sp.name.toLowerCase());
                            vue.species.splice(idx, 0, dbSp);
                        }
                    });
                    for (let i = vue.species.length - 1; i >= 0; i--) {
                        vue.db.species.get(vue.species[i].slug)
                            .then(function(ret) {
                                if (ret === undefined) {
                                    vue.species.splice(i, 1);
                                }
                            })
                        ;
                    }
                });
            },
            updateCodesFromDB: async function() {
                console.debug('enter updateCodesFromDB');
                let vue = this;
                return vue.db.transaction('r', ['codes'], async tx => {
                    await vue.db.codes.each(dbCode => {
                        let vueCode_index = vue.codes.findIndex(vueCode => vueCode.slug == dbCode.slug);
                        if (vueCode_index >= 0) {
                            Object.keys(dbCode).forEach(function(prop) {
                                if (vue.codes[vueCode_index].hasOwnProperty(prop)) {
                                    vue.codes[vueCode_index][prop] = dbCode[prop];
                                }
                            });
                        } else {
                            // vue.codes.push(dbCode);
                            let idx = _.sortedIndexBy(vue.codes, dbCode, code => code.name.toLowerCase());
                            vue.codes.splice(idx, 0, dbCode);
                        }
                    });
                    for (let i = vue.codes.length - 1; i >= 0; i--) {
                        vue.db.codes.get(vue.codes[i].slug)
                            .then(function(ret) {
                                if (ret === undefined) {
                                    vue.codes.splice(i, 1);
                                }
                            })
                        ;
                    }
                });
            },
            updateLimitsFromDB: async function() {
                console.debug('enter updateLimitsFromDB');
                let vue = this;
                // vue.limits.forEach(function(vueLimit) {
                //     console.debug('vue:', vueLimit.species, vueLimit.zone, vueLimit.water_type);
                //     console.debug('null:', vueLimit.species || 'null', vueLimit.zone || null, vueLimit.water_type || null);
                // });
                return vue.db.transaction('r', ['limits'], async tx => {
                    await vue.db.limits.each(dbLimit => {
                        let vueLimit_index = vue.limits.findIndex(function(vueLimit) {
                            // console.debug('db:', dbLimit.species, dbLimit.zone, dbLimit.water_type);
                            // console.debug('vue:', vueLimit.species, vueLimit.zone, vueLimit.water_type);
                            if ((vueLimit.species || 'null') !== dbLimit.species) {
                                // console.debug('species not matched');
                                return false;
                            }
                            if ((vueLimit.water_type || 'null') !== dbLimit.water_type) {
                                // console.debug('water_type not matched');
                                return false;
                            }
                            if ((vueLimit.zone || 'null') !== dbLimit.zone) {
                                // console.debug('zone not matched');
                                return false;
                            }
                            return true;
                        });
                        if (vueLimit_index >= 0) {
                            // console.debug('found a matching limit in vue; update it from database');
                            Object.keys(dbLimit).forEach(function(prop) {
                                if (vue.limits[vueLimit_index].hasOwnProperty(prop)) {
                                    vue.limits[vueLimit_index][prop] = dbLimit[prop];
                                }
                            });
                        } else {
                            // console.debug('no matching limit in vue; add from database');
                            vue.limits.push(dbLimit);
                        }
                    });
                    for (let i = vue.limits.length - 1; i >= 0; i--) {
                        // zzz No slug here. Uses numeric key.
                        // vue.db.limits.get(vue.limits[i].slug)
                        //     .then(function(ret) {
                        //         if (ret === undefined) {
                        //             vue.limits.splice(i, 1);
                        //         }
                        //     })
                        //     .catch(function(err) {
                        //         // console.log('limit delete', JSON.parse(JSON.stringify(err)));
                        //     })
                        // ;
                        // console.debug(JSON.parse(JSON.stringify(vue.limits[i])));
                        // await vue.db.limits.each(dbLimit => {
                        //     let vueLimit_index = vue.limits.findIndex(function (vueLimit) {
                        //         if ((vueLimit.species || 'null') !== dbLimit.species) {
                        //             return false;
                        //         }
                        //         if ((vueLimit.water_type || 'null') !== dbLimit.water_type) {
                        //             return false;
                        //         }
                        //         if ((vueLimit.zone || 'null') !== dbLimit.zone) {
                        //             return false;
                        //         }
                        //         return true;
                        //     });
                        // });
                        vue.db.limits.where({
                            species: (vue.limits[i].species || 'null'),
                            water_type: (vue.limits[i].water_type || 'null'),
                            zone: (vue.limits[i].zone || 'null')
                        }).first()
                            .then(function(limit) {
                                // console.debug(limit);
                                if (limit === undefined) {
                                    vue.limits.splice(i, 1);
                                }
                            })
                            .catch(function(err) {
                                // console.log('limit delete', JSON.parse(JSON.stringify(err)));
                            })
                        ;
                    }
                });
            },
            updateFromServer: function () {
                console.debug('enter updateFromServer 2');
                if (!this.online) {
                    return;
                }
                let vue = this;
                console.debug('finna get last_update from database');
                vue.db.settings.get('last_update')
                    .then(last_update => {
                        console.debug('from db: ', last_update);
                        if (!testing) {
                            last_update = (last_update !== undefined) ? last_update.datetime : '0000-00-00';
                            let hours = Math.floor(((new Date()) - (new Date(last_update))) / 60 / 60 / 1000);
                            if (hours < 24) {
                                return;
                            }
                        } else {
                            last_update = '0000-00-00';
                        }
                        console.debug('after adjustment: ', last_update);
                        this.axios.get(this.apiBase + '/updates?last_update=' + last_update)
                            .then(async response => {
                                console.debug('got response');
                                console.debug(response.data);
                                console.debug('do counties');
                                response.data.counties.forEach(function(county) {
                                    if (county.deleted) {
                                        vue.db.counties.delete(county.slug)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    } else {
                                        delete county.deleted;
                                        vue.db.counties.put(county)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    }
                                });

                                console.debug('do waters');
                                response.data.waters.forEach(function(water) {
                                    if (water.deleted) {
                                        vue.db.waters.delete(water.slug)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    } else {
                                        delete water.deleted;
                                        vue.db.waters.put(water)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    }
                                });

                                console.debug('do seasons');
                                response.data.seasons.forEach(function(season) {
                                    if (season.deleted) {
                                        vue.db.seasons.delete(season.slug)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    } else {
                                        delete season.deleted;
                                        vue.db.seasons.put(season)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    }
                                });

                                console.debug('do limits');
                                response.data.limits.forEach(function(limit) {
                                    if (limit.deleted) {
                                        vue.db.limits.where({
                                            species: (limit.species || 'null'),
                                            water_type: (limit.water_type || 'null'),
                                            zone: (limit.zone || 'null')
                                        }).delete()
                                            .then(function(n) {
                                                // console.debug('deleted', n, 'limits by key');
                                            })
                                            .catch(function(err) {
                                                console.error(err);
                                            })
                                        ;
                                    } else {
                                        delete limit.deleted;
                                        vue.db.limits.where({
                                            species: (limit.species || 'null'),
                                            water_type: (limit.water_type || 'null'),
                                            zone: (limit.zone || 'null')
                                        }).primaryKeys()
                                            .then(function(pkeys) {
                                                vue.db.limits.put(limit, pkeys[0])
                                                    .catch(error => {
                                                        console.error(error);
                                                    })
                                                ;
                                            })
                                            .catch(function(err) {
                                                console.error(err);
                                            })
                                        ;
                                    }
                                });

                                console.debug('do species');
                                response.data.species.forEach(function(oneSpecies) {
                                    if (oneSpecies.deleted) {
                                        vue.db.species.delete(oneSpecies.slug)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    } else {
                                        delete oneSpecies.deleted;
                                        vue.db.species.put(oneSpecies)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    }
                                });

                                console.debug('do activities');
                                response.data.activities.forEach(function(activity) {
                                    if (activity.deleted) {
                                        vue.db.activities.delete(activity.slug)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    } else {
                                        delete activity.deleted;
                                        vue.db.activities.put(activity)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    }
                                });

                                console.debug('do codes');
                                response.data.codes.forEach(function(code) {
                                    if (code.deleted) {
                                        vue.db.codes.delete(code.slug)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    } else {
                                        delete code.deleted;
                                        vue.db.codes.put(code)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                        ;
                                    }
                                });

                                console.debug('do settings');
                                vue.db.settings.put({
                                    slug: 'last_update',
                                    datetime: (new Date()).toISOString()
                                });

                                await vue.updateCountiesFromDB();
                                await vue.updateWatersFromDB();
                                await vue.updateActivitiesFromDB();
                                await vue.updateSpeciesFromDB();
                                await vue.updateCodesFromDB();
                                await vue.updateLimitsFromDB();

                            })
                            .catch(error => {
                                console.error(error);
                            })
                        ;
                    })
                    .catch(err => {
                        console.error('failed to get last_update from local db', err);
                    })
                ;
            },
            loadWaterDepthFromServer: function(water_slug) {
                if (!this.online) {
                    return;
                }
                if (this.nDepthsLoaded >= this.nDepthsAllowed) {
                    return;
                }
                let vue = this;
                return this.axios.get(this.apiBase + '/waters/' + water_slug + '/depth-chart')
                    .then(response => {
                        vue.db.depths.put({
                            slug: water_slug,
                            data: response.data,
                        });
                        vue.nDepthsLoaded++;
                        vue.counties.some(function(county, county_index) {
                            county.waters.some(function(water, water_index) {
                                if (water.slug === water_slug) {
                                    vue.counties[county_index].waters[water_index].depthLoaded = true;
                                    return true;
                                }
                            });
                        });
                        return response.data;
                    })
                ;
            },
            toggleWaters: function(county_slug) {
                let vue = this;
                let county_index = vue.counties.findIndex(county => county.slug === county_slug);
                vue.counties[county_index].showWaters = !vue.counties[county_index].showWaters;
            },
            showWaterDetails: function(water_slug) {
                let vue = this;

                vue.db.waters
                    .where({slug: water_slug})
                    .first()
                    .then(function(water) {
                        vue.water = water;
                        vue.showWater = true;
                    })
                    .catch(function(err) {
                        console.error(err);
                    });
            },
            showWaterDepths: function(water_slug) {
                let vue = this;

                vue.db.depths
                    .where({slug: water_slug})
                    .first()
                    .then(async function(ret) {
                        if (ret === undefined) {
                            console.log('Requested depth map not available');
                            return;
                        }
                        document.getElementById('overlay').getElementsByClassName('content')[0].innerHTML = '<img src="data:image/jpeg;base64, ' + ret.data + '">';
                        document.getElementById('overlay').classList.remove('hidden');
                    })
                    .catch(function(err) {
                        console.error(err);
                    });
            },
            loadWaterDepths: function(water_slug) {
                // let vue = this;

                this.loadWaterDepthFromServer(water_slug)
                    .then(function(ret) {
                        // document.getElementById('overlay').getElementsByClassName('content')[0].innerHTML = '<img src="data:image/jpeg;base64, ' + ret.data + '">';
                        // document.getElementById('overlay').classList.remove('hidden');
                    })
                    .catch(function(err) {
                        console.error(err);
                    });
            },
            // loadWaterDepths: function(water_slug) {
            //     let vue = this;
            //
            //     vue.db.depths
            //         .where({slug: water_slug})
            //         .first()
            //         .then(async function(ret) {
            //             if (ret === undefined) {
            //                 ret = {
            //                     data: await vue.loadWaterDepthFromServer(water_slug)
            //                 };
            //             }
            //             document.getElementById('overlay').getElementsByClassName('content')[0].innerHTML = '<img src="data:image/jpeg;base64, ' + ret.data + '">';
            //             document.getElementById('overlay').classList.remove('hidden');
            //         })
            //         .catch(function(err) {
            //             console.error(err);
            //         });
            // },
            removeWaterDepth: function(water_slug) {
                let vue = this;

                return vue.db.depths.delete(water_slug)
                    .then(() => {
                        vue.counties.some(function(county, county_index) {
                            county.waters.some(function(water, water_index) {
                                if (water.slug === water_slug) {
                                    vue.counties[county_index].waters[water_index].depthLoaded = false;
                                    vue.nDepthsLoaded = Math.max(0, (vue.nDepthsLoaded - 1));
                                    return true;
                                }
                            });
                        });
                    })
                    .catch(err => { console.error(err);})
                    ;
            },
            closeOverlay: function() {
                document.getElementById('overlay').classList.add('hidden');
            },
            dump: function(obj) {
                console.debug(JSON.parse(JSON.stringify(obj)));
            },
        },
*/
        methods: {
            formatDateTime: function(timestamp) {
                return dayjs.unix(timestamp).format('MMMM D [at] h:mm a');
            },
            formatDate: function(timestamp) {
                // return dayjs.unix(timestamp).format('ddd, MMMM D');
                return dayjs.unix(timestamp).format('ddd D');
            },
            formatTime: function(timestamp) {
                return dayjs.unix(timestamp).format('ddd h:mm a');
            },
            formatTemp: function(k, digits) {
                digits = digits || 0;
                return ((k * 9 / 5) - 459.67).toFixed(digits);
            },
            formatWind: function(speed, deg) {
                let mph = (speed * 0.62137).toFixed(0);
                let points = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW', 'N'];
                let dir = points[Math.round(deg / 22.5)];
                return mph + ' mph ' + dir;
            },
            formatPercent: function(percent) {
                return (percent * 100).toFixed(0);
            },
            formatLength: function(mm, digits) {
                digits = digits || 1;
                return (mm / 25.4).toFixed(digits);
            },
            getMoonIconNumber: function(phase) {
                return Math.round(phase * 26) % 26;
            },
            loadFromServer: function() {
                let vue = this;
                return axios.get('/api')
                    .then(function(response) {
                        vue.weather = response.data;
                        vue.lastQuery = Math.min(vue.weather.current.dt, Math.floor(Date.now() / 1000));
                        console.debug(vue.weather);
                    })
                    .catch(function(error) {
                        console.error(error);
                    })
                ;
            },
        },
/*
        mounted: async function() {
            // this.apiBase = 'https://fish/api';
            this.apiBase = '';
            this.axios = require('axios').default;
            this.axios.defaults.baseURL = 'https://fishapi.mdiwebsites.com/api';

            let vue = this;

            vue.db.settings.get('user')
                .then(function(user) {
                    vue.user.displayName = user.displayName;
                    vue.user.loggedIn = true;
                    vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.apiToken;
                })
                .catch(function() {

                })
            ;

            await this.updateCountiesFromDB();
            await this.updateWatersFromDB();
            await this.updateActivitiesFromDB();
            await this.updateSpeciesFromDB();
            await this.updateCodesFromDB();
            await this.updateLimitsFromDB();

            this.axios.get(this.apiBase + '/ping')
                .then(function() {
                    console.debug('ping detects online');
                    vue.online = true;
                    vue.updateFromServer();
                })
                .catch(function() {
                    console.debug('ping detects offline');
                    vue.online = false;
                })
                .finally(function() {
                    window.addEventListener('offline', function(e) { console.debug('offline event'); vue.online = false; });
                    window.addEventListener('online', function(e) { console.debug('online event'); vue.online = true; });
                })
            ;
        }
*/
        mounted: function() {
            let vue = this;

            PullToRefresh.init({
                mainElement: 'body',
                onRefresh() {
                    return vue.loadFromServer()
                        .catch(function(error) {
                            console.error('onRefresh', error);
                        })
                    ;
                }
            });

            this.loadFromServer();
        },
    });

    return true;
})();
